<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
		<div class="w-100 h-auto flex flex-column">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
				<el-form-item label="会议日期" prop="time">
				  <el-input v-model="form.time" placeholder="请输入会议日期(如:2023年12月21日)" type="text" clearable style="width: 650px;"/>
				</el-form-item>
				<el-form-item label="会议地点" prop="place">
				  <el-input v-model="form.place" placeholder="请输入会议地点" type="text" clearable style="width: 650px;"/>
				</el-form-item>
				<el-form-item label="会议主题" prop="title">
				  <el-input v-model="form.title" placeholder="请输入会议主题" type="text" clearable style="width: 650px;"/>
				</el-form-item>
				<el-form-item label="发送会员" prop="ids">
				  <el-input v-model="names" placeholder="请选择会员,一行一个" type="textarea" clearable readonly rows="10" @focus="selUsers"/>
				  <!-- <small class="d-block text-secondary" style="line-height: 20px;">请输入登录手机号,一行一个</small> -->
				</el-form-item>
			</el-form>
			<div class="w-100 flex-all mt-2">
			  <el-button type="primary" size="medium" @click="handleSubmit" style="margin-left: 30px;">确 定</el-button>
			</div>
		</div>
		</el-card>
		
		
		<!-- 选择用户 -->
		<el-dialog :visible.sync="openUsers" width="70%" :close-on-click-modal="false" append-to-body :title="title">
			<div class="w-100 flex align-center">
				<el-form ref="page" :model="page" label-width="80px" size="small" inline>
					<el-form-item label="单位名称" label-width="70px">
						<el-input v-model="page.keyword" placeholder="请输入单位名称" type="text" clearable
							style="width: 230px" />
					</el-form-item>
					<el-form-item label="城市" label-width="50px">
						<el-input v-model="page.city" placeholder="请输入城市名称" type="text" clearable
							style="width: 230px" />
					</el-form-item>
					<el-form-item label="类型" label-width="50px">
						<el-select v-model="page.grade" multiple placeholder="请选择类型" size="small" class="w-100">
						   <el-option label="全部" value=""></el-option>
						   <el-option v-for="(item,index) in grades" :key="index"
							 :label="item.name"
							 :value="item.id">
							 </el-option>
						   </el-select>
						</el-form-item>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" icon="el-icon-search" size="mini" @click="getUsers">查询</el-button>
						<el-button icon="el-icon-refresh" size="mini" @click="resetQuery()">重置</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-table
			  :data="usersData"
			  @selection-change="handleSelectionChange"
			  style="width: 100%">
			  <el-table-column
				type="selection"
				width="55">
			   </el-table-column>
			  <el-table-column
			  label="ID"
			  align="center"
			  prop="id">
			  </el-table-column>
			  <el-table-column
			  label="名称"
			  align="center"
			  prop="name">
			  </el-table-column>
			  <el-table-column
			  label="类型"
			  align="center"
			  prop="grade_name">
			  </el-table-column>
			  <el-table-column
			  label="法人"
			  align="center"
			  prop="legal_person">
			  </el-table-column>
			  <el-table-column
			  label="城市"
			  align="center"
			  prop="city">
			  </el-table-column>
			  <el-table-column
			  label="操作"
			  width="200"
			  align="center">
			  <template slot-scope="scope">
				<el-button
				type="danger"
				size="mini"
				@click="handleSel(scope.$index, scope.row)">选择</el-button>
			  </template>
			  </el-table-column>
			</el-table>
			<!-- 分页 -->
			<span slot="footer" class="dialog-footer">
				<div class="w-100 flex flex-column">
					<div slot="footer" class="dialog-footer flex-all">
					  <el-button type="danger" size="medium" @click="openUsers = false" style="width: 200px;">确 定</el-button>
					</div>
					<div class="px-2" style="flex: 1;">
					 <pagination
						 :total="page.total"
						 auto-scroll
						 :page.sync="page.page"
						 :limit.sync="page.size"
						 @pagination="getUsers"
					 />
					</div>
				</div>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import GlobalTips from "@/components/GlobalTips";
import common from "@/common/mixins/common.js"
import Breadcrumb from '@/components/Breadcrumb';
export default {
	inject:['app'],
	mixins:[common],
	components: {
		GlobalTips,
		Breadcrumb,
	},
	data() {
		return {
			preUrl: 'hytz',
			autoRequest: false,
			form:{
				time:'',
				place:'',
				title:'',
				ids:[],
			},
			rules:{
				time: [
				  { required: true, message: '请输入会议时间', trigger: ['blur','change'] }
				],
				place: [
				  { required: true, message: '请输入会议地点', trigger: 'blur' }
				],
				title: [
				  { required: true, message: '请输入会议主题', trigger: 'blur' }
				],
               ids: [
                 { required: true, message: '请选择会员', trigger: ['blur','change'] }
               ],
			},
			page: {
				page: 1,
				limit: 10,
				total:0,
				keyword: '',
				grade:'',
				city:'',
			},
			openUsers:false,
			usersData:[],
			grades:[],
			names:''
		}
	},
	mounted() {
		// this.getCkqx()
		this.getGrades()
	},
	methods:{
		getGrades(){
			this.axios.get('/manage/user_grade/all').then(res=>{
			  if(res.status){
				let  grades = res.data
				let index = grades.findIndex(v=>v.id == 2)
				grades.splice(index,1)
				this.grades = grades
			  }
			})
		},
		resetQuery(){
			this.page = {
				page: 1,
				limit: 10,
				total:0,
				keyword: '',
				grade:'',
				city:'',
			}
			this.areaOptions = []
			this.getUsers()
		},
		selUsers(){
			this.getUsers()
			this.$nextTick(()=>{
			  this.title = '会员列表'
			  this.openUsers = true
			})
		},
		handleSelectionChange(e){
			let ids = [];
			let names = ''
			if(e.length > 0){
				e.forEach((item)=>{
					ids.push(item.id);
					names += item.name+'\n';
				})
			}
			this.form.ids = ids
			this.names = names
		},
		getUsers(){
			this.axios.get('/manage/user/list',{
			  params:this.page
			}).then(res=>{
			  this.page.total = res.total
			  this.usersData = res.data
			})
		},
		handleSel(index,item){
			console.log(item)
			let ids = [];
			ids.push(item.id)
			this.form.ids = ids
			this.names = item.name
			this.openUsers = false
		},
		handleSubmit() {
		  this.$refs.form.validate(valid => {
		    if (valid) {
		        this.axios.post('/manage/user/hytx', this.form).then(res => {
		          if (res.status) {
		            this.$message.success('发送成功')
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		    }
		  })
		},
		cancel(){
			this.$router.go(-1);
		},
	},
}
</script>

<style>
</style>